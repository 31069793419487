import type { RefObject, SyntheticEvent } from 'react'
import { useCallback } from 'react'
import clsx from 'clsx'
import styles from './three-d-tour-button.module.css'
import type { TourFromHomeItem } from '../../../../features/detail/tour-from-home/get-tour-from-home-data'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { yieldOrContinue } from 'main-thread-scheduling'
import { Anchor } from '../../../../components/anchor/anchor'
import type { TourFromHomeModalRef } from '../../../../features/detail/tour-from-home/tour-from-home-modal'

interface ThreeDTourButtonProps {
  listingId: string
  tourInfo: TourFromHomeItem[]
  className?: string
  tourFromHomeModalRef?: RefObject<TourFromHomeModalRef>
}

export function ThreeDTourButton({
  listingId,
  tourInfo,
  className,
  tourFromHomeModalRef,
}: ThreeDTourButtonProps) {
  const isListingCardRedesignVersionTwo =
    useFeatureVariable<number>(['listing_card_redesign', 'version'], 0) === 2

  const handleClick = useCallback(
    async function handleClick(e: SyntheticEvent<HTMLButtonElement>) {
      e.preventDefault()

      await yieldOrContinue('smooth')
      tourFromHomeModalRef?.current?.open({
        index: 0,
        data: { listingId, showOnly3dTours: true, data: tourInfo },
      })

      await yieldOrContinue('smooth')
      window.eventTracker?.track('click', {
        section: 'listing',
        item: '3d_tour',
        listing_id: listingId,
      })
    },
    [listingId, tourFromHomeModalRef, tourInfo]
  )

  const classNames = isListingCardRedesignVersionTwo
    ? styles.threeDTourButtonRedesignVersionTwo
    : styles.threeDTourButtonRedesign

  return (
    <Anchor
      data-tag_action="ignore"
      className={clsx(classNames, className)}
      aria-label="3d-tour button"
      data-tid="3d-tour"
      onClick={tourFromHomeModalRef ? handleClick : undefined}
      as="button"
    >
      3D Tour
    </Anchor>
  )
}
