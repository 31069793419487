import clsx from 'clsx'
import type { ChangeEvent, ReactNode, KeyboardEvent } from 'react'
import { forwardRef, useEffect, useId, useState } from 'react'
import styles from './toggle-button.module.css'

export type ToggleButtonProps = {
  type: 'radio' | 'checkbox'
  label: ReactNode
  checked?: boolean
  value?: string
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  'data-tag_item'?: string
}

export function sortAndSelectRange(
  currentValueSelection: string,
  previousSelectedValue: string | undefined | null,
  selectedValues: string[],
  options: Omit<ToggleButtonProps, 'type'>[]
): string[] {
  const sortedValues = selectedValues.sort(
    (a, b) =>
      options.findIndex((option) => option.value === a) -
      options.findIndex((option) => option.value === b)
  )

  // Get the index of the current start and end of the selected range
  const startIndex = options.findIndex(
    (option) => option.value === sortedValues[0]
  )
  const endIndex = options.findIndex(
    (option) => option.value === sortedValues[sortedValues.length - 1]
  )

  // Get the index of the previous and current selections
  let previousIndex = options.findIndex(
    (option) => option.value === previousSelectedValue
  )
  const currentIndex = options.findIndex(
    (option) => option.value === currentValueSelection
  )

  // if previous value is null and previous index is -1, treat the start index as the previous index
  if (previousIndex === -1) {
    previousIndex = startIndex
  }

  // If the previous selection was a single value and that same value is clicked, return empty list
  if (currentIndex === startIndex && currentIndex === endIndex) {
    return []
  }

  // if there was no previous value OR current selection is min or max, that should be the only value selected
  if (
    startIndex === -1 ||
    currentIndex === startIndex ||
    currentIndex === endIndex
  ) {
    return [currentValueSelection]
  }

  // Otherwise, update the range based on the direction of the selection
  if (previousIndex > currentIndex) {
    // If the previous selection is greater than the current, make the current selection the new minimum
    return options
      .slice(currentIndex, endIndex + 1)
      .map((option) => option.value || '')
  } else {
    // If the previous selection is less than the current, make the current selection the new maximum
    return options
      .slice(startIndex, currentIndex + 1)
      .map((option) => option.value || '')
  }
}

/**
 * This component is either a checkbox or a radio input that masquerades as a button.
 */
const ToggleButton = forwardRef<HTMLInputElement, ToggleButtonProps>(
  function ToggleButton(props: ToggleButtonProps, ref) {
    const id = `${useId()}-toggle-button`
    const value = props.value || ''

    return (
      <>
        <input
          ref={ref}
          className={styles.toggleButtonInput}
          id={id}
          value={value}
          name={props.name}
          checked={props.checked}
          type={props.type || 'checkbox'}
          tabIndex={-1}
          onChange={(e) => {
            props.onChange?.(e)
          }}
          data-tag_action="ignore" // Prevent duplicate GTM events from clicking the label and the input
        />
        <label
          className={clsx(styles.toggleButtonLabel, 'toggleButton')}
          htmlFor={id}
          data-tid={`${value}-button`}
          data-tag_item={props['data-tag_item']}
          tabIndex={0}
          onKeyDownCapture={(e: KeyboardEvent<HTMLLabelElement>) => {
            // This isn't the default radio behavior, but it might make some users happy.
            if (e.key === 'Enter') {
              e.currentTarget.click()
            }
          }}
        >
          {props.label}
        </label>
      </>
    )
  }
)

type ToggleButtonGroupOwnProps = {
  options: Omit<ToggleButtonProps, 'type'>[]
  onChange?: (value: string | string[], currentValue?: string) => void
  orientation?: 'horizontal' | 'vertical'
  previousValue?: string | null
  className?: string
}

type RadioGroupProps = {
  value?: string
  type: 'radio'
}

type CheckboxGroupProps = {
  value?: string[]
  type: 'checkbox'
  selectionPattern?: 'SINGLE_SELECTION' | 'RANGE_SELECTION' | null
}

export function ToggleButtonGroup(
  props: ToggleButtonGroupOwnProps & (RadioGroupProps | CheckboxGroupProps)
) {
  const type = props.type
  const [values, setValues] = useState<string[]>(
    typeof props.value === 'string' ? [props.value] : props.value || []
  )

  useEffect(() => {
    setValues(
      typeof props.value === 'string' ? [props.value] : props.value || []
    )
  }, [props.value])

  function handleChange(buttonOnChange?: ToggleButtonProps['onChange']) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      let newValues: string[] = []
      if (type === 'checkbox') {
        if (props.selectionPattern === 'SINGLE_SELECTION') {
          newValues = values.includes(value)
            ? values.filter((val) => val !== value)
            : [value]
        } else if (props.selectionPattern === 'RANGE_SELECTION') {
          newValues = sortAndSelectRange(
            value,
            props.previousValue,
            values,
            props.options
          )
        } else {
          newValues = values.includes(value)
            ? values.filter((el) => el !== value)
            : values.concat(value)
        }

        buttonOnChange?.(e)
        props.onChange?.(newValues, value)
      } else if (type === 'radio') {
        newValues = [value]
        buttonOnChange?.(e)
        props.onChange?.(value)
      }

      setValues(newValues)
    }
  }

  return (
    <div
      className={clsx(props.className, styles.toggleButtonGroup, {
        [styles.toggleButtonGroupVertical]: props.orientation === 'vertical',
      })}
    >
      {props.options?.map((option, i) => (
        <div key={i} className={styles.toggleButtonGroupItem}>
          <ToggleButton
            type={type}
            value={option.value ? option.value : ''}
            name={option.name}
            checked={
              typeof option.value === 'string' && values.includes(option.value)
            }
            {...option}
            onChange={handleChange(option.onChange)}
            data-tag_item={option['data-tag_item'] || option.value}
          />
        </div>
      ))}
    </div>
  )
}
ToggleButtonGroup.displayName = 'ToggleButton.Group'

const CompoundToggleButton = Object.assign({}, ToggleButton, {
  Group: ToggleButtonGroup,
})

export { CompoundToggleButton as ToggleButton }
