export const GOOGLE_MAPS_API_KEY = 'AIzaSyBaSBnz2EFl0EF3sAIyf-0fMwIdG0bFCDs'
export const GOOGLE_STATIC_STREET_VIEW_API_KEY =
  'AIzaSyBActXJwPaFVkrkKPEWVTKruOWFk7lGcTg'
export const GOOGLE_MAPS_PDP_ZOOM = 12
export const GOOGLE_MAPS_PDP_STREET_SEARCH_RADIUS = 200
export const GOOGLE_MAPS_MISSING_STREET_VIEW_TEXT =
  "Sorry! Google Maps doesn't have Street View for this property."

export const MAP_IDLE_DEBOUNCE_TIMEOUT_MS = 150
type Zoom = number
type DistanceInKm = number

export const CLUSTER_ZOOM_DISTANCE_MAP: Record<Zoom, DistanceInKm> = {
  11: 1.2, // only used when srp_map_updates > 1, shows 60 price markers + regular markers
  12: 0.6, // only used when srp_map_updates > 1, shows 60 price markers + regular markers
  13: 0.3, // show all price markers from > this zoom level
  14: 0.125,
  15: 0.075,
  16: 0.05,
  17: 0.0125,
}
export const DEFAULT_ZOOM = 12
export const STATIC_MAP_ZOOM = 13
export const MIN_ZOOM = 5
export const MAX_ZOOM = 21
export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi.school',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]
export const STATIC_MAP_STREET_BASE_URL =
  'https://maps.googleapis.com/maps/api/streetview?'
export const STATIC_MAP_BASE_URL =
  'https://maps.googleapis.com/maps/api/staticmap?'
export const STATIC_MAP_DEFAULT_TYPE = 'hybrid'
export const STATIC_MAP_DEAFULT_MAP_FORMAT = 'png'
