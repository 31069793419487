import { ReactComponent as CheckIcon } from '@brand/icons/check.svg'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Anchor } from '../../components/anchor/anchor'
import { Heading } from '../../components/heading/heading'
import { ListingCardPreviewGroup } from '../listing-card-preview-group/listing-card-preview-group'
import { Modal } from '../modals/modal'
import { includeUnpaidPdpAds } from '../ads/include-unpaid-pdp-ads'
import { THANK_YOU_AD_SLOTS } from '@brand/features/ads/aditude-ad-slots.const'
import { getAdvertiserTargets } from '../ads/get-advertiser-targets'
import styles from './thank-you.module.css'

import type { AsyncData } from '@swan-io/boxed'
import type { ThankYou_ListingFragment } from './__generated__/thank-you.gql'
import type { SimilarOrViewed_ListingFragment } from '../listing-card-preview-group/__generated__/similar-properties.gql'
import { useOneClickLeadActive } from '../one-click-lead/use-show-one-click-lead'

type ThankYouProps = {
  heading: string
  listing: ThankYou_ListingFragment
  onClose?: () => void
  isMobile: boolean
  isPdp: boolean
  similarProperties: AsyncData<SimilarOrViewed_ListingFragment[]>
}

const ThankYouSkeletons = () => (
  <div className={styles.similarProperties}>
    <ListingCardPreviewGroup
      isHorizontal={false}
      ad={null}
      listings={[]}
      fetching
      dataTagSectionPrefix="similar_properties"
    />
  </div>
)

const HeadingMessage = ({
  heading,
  oneClickLeadActive,
}: {
  heading?: string
  oneClickLeadActive: boolean
}) => {
  const [hideHeadingMessage, setHideHeadingMessage] = useState(false)
  useEffect(() => {
    if (oneClickLeadActive) {
      const fiveSeconds = 5_000
      const timer = setTimeout(() => setHideHeadingMessage(true), fiveSeconds)
      return () => clearTimeout(timer)
    }
  }, [oneClickLeadActive, setHideHeadingMessage])

  return (
    <div
      className={clsx(styles.heading, hideHeadingMessage && styles.hideHeading)}
      data-tid="thank-you-heading"
    >
      <span className={styles.checkIconWrapper}>
        <CheckIcon />
      </span>

      {heading}
    </div>
  )
}

export function ThankYou(props: ThankYouProps) {
  const oneClickLeadActive = useOneClickLeadActive()

  return (
    <>
      <header
        className={clsx(
          styles.header,
          oneClickLeadActive && styles.headerWithTitle
        )}
      >
        {oneClickLeadActive && (
          <Heading as="h3">
            Contact Similar Properties Near {props.listing.location?.city}
          </Heading>
        )}
        <HeadingMessage
          heading={props.heading}
          oneClickLeadActive={oneClickLeadActive}
        />
        <Modal.CloseButton onClose={props.onClose}>
          <Anchor as="button" variant="dark">
            Close
          </Anchor>
        </Modal.CloseButton>
      </header>

      {props.similarProperties.match({
        NotAsked: () => <ThankYouSkeletons />,
        Loading: () => <ThankYouSkeletons />,
        Done: (similarListings) => (
          <>
            {similarListings.length > 0 && (
              <div className={styles.similarProperties}>
                {!oneClickLeadActive && (
                  <Heading as="h3" withBodyFont>
                    Similar Properties Near {props.listing.location?.city}
                  </Heading>
                )}
                <ListingCardPreviewGroup
                  isHorizontal={false}
                  ad={{
                    config: includeUnpaidPdpAds(
                      props.listing.tplsource,
                      props.listing.offMarket
                    )
                      ? THANK_YOU_AD_SLOTS.UNPAID_AD
                      : THANK_YOU_AD_SLOTS.PAID_AD,
                    targeting: getAdvertiserTargets(props.listing),
                  }}
                  listings={similarListings ?? []}
                  openInNewTab={!props.isPdp && !props.isMobile}
                  isPostLead
                  showTourCta
                  oneClickLeadActive={oneClickLeadActive}
                  dataTagSectionPrefix="similar_properties"
                />
              </div>
            )}
          </>
        ),
      })}
    </>
  )
}
