/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { ListingPhone_ListingFragmentDoc } from '../../listing-phone/__generated__/listing-phone.gql';
import { TelephonyFields_ListingFragmentDoc, CtaButtons_ListingFragmentDoc, CtaButtons_TourProviderDetailsFragmentDoc, CtaButtons_LocationFragmentDoc } from '../../cta-buttons/__generated__/cta-buttons.gql';
import { AdTarget_ListingFragmentDoc } from '../../ads/__generated__/ad-targets.gql';
export type ThankYou_ListingFragment = { id: string, name?: string | null, propertyType?: Types.PropertyType | null, tplsource?: string | null, offMarket: boolean, hasLeadEmails: boolean, isActive: boolean, isBasic: boolean, phoneMobileSem?: string | null, phoneMobile?: string | null, phoneDesktop?: string | null, phoneDesktopText?: string | null, phoneDesktopSem?: string | null, phoneDesktopSemText?: string | null, phoneMobileText?: string | null, phoneMobileSemText?: string | null, revenue?: number | null, zScore?: number | null, NV_RPL?: number | null, adTargetCode?: string | null, photos?: Array<{ id: string } | null> | null, location?: { city?: string | null, state?: string | null, lat?: number | null, lng?: number | null } | null, bedRange?: { min?: number | null, max?: number | null } | null, priceRange?: { min?: number | null, max?: number | null } | null, optimizedPhotos?: Array<{ id: string }>, mitsPhone: { raw?: string | null, formatted?: string | null }, adPartners: Array<{ name: string }>, telephony: { isSwapPhoneEligible: boolean, webNumber: { raw?: string | null, formatted?: string | null }, semNumber: { raw?: string | null, formatted?: string | null } } };

export const ThankYou_ListingFragmentDoc = `
    fragment ThankYou_ListingFragment on Listing {
  id
  name
  propertyType
  tplsource
  photos {
    id
  }
  location {
    city
    state
    lat
    lng
  }
  bedRange {
    min
    max
  }
  priceRange {
    min
    max
  }
  optimizedPhotos(sessionId: $optimizedPhotosSessionId) @include(if: $withOptimizedPhotos) {
    id
  }
  offMarket
  hasLeadEmails
  ...ListingPhone_ListingFragment
  ...AdTarget_ListingFragment
}
    `;



