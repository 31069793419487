import { PropertyTypeFilter } from '../../../__generated__/api-types'

export const resultsPerPage = 35
export const enableBookTourCarouselFilterCard = true
export const houseLandingPagePropertyTypes = [
  PropertyTypeFilter.Houses,
  PropertyTypeFilter.Duplexes,
  PropertyTypeFilter.Triplexes,
]
export const withFullWidthSearchResultsHeader = true
export const enableRecentlyUpdatedCarousel = false
export const propertyTypeMap = {
  APARTMENTS: 'Apartment',
  HOUSE: 'House',
  TOWNHOME: 'Townhome',
  CONDO: 'Condo',
  DUPLEX: 'Duplex',
  TRIPLEX: 'Triplex',
}
