import { getCurrencyText } from '@rentpath/web-utils'
import type {
  AggregatesLowHigh,
  FloorPlan,
  Listing,
  ListingAvailabilityStatus,
} from '../../__generated__/api-types'
import {
  CONTACT_FOR_PRICE,
  AVAILABILITY_UNKNOWN,
  PRICE_UNAVAILABLE,
} from '@brand/slots/detail/floor-plans/const'
import type { Marker_ListingFragment } from '../../features/search/search-map/__generated__/markers.gql'
import { isAvailable } from '../detail/utils/is-available'

const DEFAULT_MIN_PRICE = 0
const DEFAULT_MAX_PRICE = 99999

type GetListingPriceTextProps = {
  priceRange?: AggregatesLowHigh | undefined | null
  isBasicListing?: boolean
  fallbackPriceText?: string | null
  availabilityStatus?: ListingAvailabilityStatus
  tplSource?: Listing['tplsource']
  floorPlans?: Omit<FloorPlan, 'units'>[] | null
  isRentSrpRedesignEnabled?: boolean
  isSingleFloorPlan?: boolean
}

export function getListingPriceText({
  priceRange,
  isBasicListing = false,
  fallbackPriceText,
  availabilityStatus,
  floorPlans,
  isRentSrpRedesignEnabled,
  isSingleFloorPlan,
}: GetListingPriceTextProps) {
  const isAvailableListing =
    availabilityStatus && isAvailable(availabilityStatus)
  const priceTerm = floorPlans?.length && floorPlans[0].priceTerm

  if (isBasicListing && !isAvailableListing) return AVAILABILITY_UNKNOWN
  if (!priceRange) return fallbackPriceText ?? PRICE_UNAVAILABLE

  const { low: min, high: max } = priceRange
  const minPrice = min && min === DEFAULT_MIN_PRICE ? null : min
  const maxPrice = max && max === DEFAULT_MAX_PRICE ? null : max

  const price = minPrice || maxPrice

  if (!price) {
    return CONTACT_FOR_PRICE
  }

  const priceText = getCurrencyText(price)

  if (minPrice && (!maxPrice || minPrice < maxPrice)) {
    return `${priceText}+`
  }

  if (isRentSrpRedesignEnabled && isSingleFloorPlan && priceTerm) {
    return `${priceText}${priceTerm}`
  }

  return priceText
}

function formatPriceDisplay(price: number) {
  return ['$', price >= 1000 ? `${(price / 1000).toFixed(1)}k` : price].join('')
}
type GetMapMarkerPriceTextProps = {
  priceRangeFromFilterMatchResults: AggregatesLowHigh | undefined
  listingPrice?: Marker_ListingFragment['price']
  listingMarkerPriceText?: Marker_ListingFragment['mapMarkerPriceText']
}

export function getMapMarkerPriceText({
  priceRangeFromFilterMatchResults,
  listingPrice,
  listingMarkerPriceText,
}: GetMapMarkerPriceTextProps) {
  // Price Unavailable
  if (
    !listingPrice &&
    !priceRangeFromFilterMatchResults?.high &&
    !priceRangeFromFilterMatchResults?.low
  ) {
    return null
  }

  // if no filter and api gives us a price text to use
  if (!priceRangeFromFilterMatchResults && listingMarkerPriceText) {
    return listingMarkerPriceText
  }

  // Scenario - entry in filterMatchResults exists for this listing, but low and high price are unspecified based on API logic.
  if (
    !priceRangeFromFilterMatchResults?.low &&
    !priceRangeFromFilterMatchResults?.high
  ) {
    return null
  }

  // format the price based on filters
  const { low: min, high: max } = priceRangeFromFilterMatchResults
  const minPrice = min && min === DEFAULT_MIN_PRICE ? null : min
  const maxPrice = max && max === DEFAULT_MAX_PRICE ? null : max

  const price = minPrice || maxPrice || listingPrice

  if (!price) {
    return null
  }

  return formatPriceDisplay(price)
}
