'use client'

import styles from '@brand/slots/home/home-page-hero/home-page-hero.module.css'
import { ModalName } from '../modals/modals.config'
import { useOpenModal } from '../modals/use-open-modal'
import { useShowOnboardingModal } from '../onboarding/hooks/use-show-onboarding-modal'
import {
  SearchComboBox,
  type SearchComboBoxItem,
} from '../search/search-combobox/search-combobox'
import { assignOrOpenPath } from '../search/utils/assign-or-open-path'
import { ALL_PROPERTY_TYPES_SLUG } from '@brand/config/property-types'
import { useRef } from 'react'

export function HomePageHeroCombobox({
  onboardingIsActive,
  isMobile,
  recentSearch,
  className,
}: {
  onboardingIsActive: boolean
  isMobile: boolean
  recentSearch: string
  className?: string
}) {
  const onSuccessCallback = useShowOnboardingModal()
  const openModal = useOpenModal()
  const openOnboardingModal = (selectedItem: SearchComboBoxItem) => {
    openModal({
      id: ModalName.ONBOARDING,
      props: { selectedItem },
    })
  }
  const formRef = useRef<HTMLFormElement | null>(null)

  return (
    <form
      action="/api/search"
      method="POST"
      className={styles.form}
      ref={formRef}
    >
      <SearchComboBox
        className={className}
        propertyTypes={ALL_PROPERTY_TYPES_SLUG}
        searchResultsHeaderClassName={styles.searchResultHeader}
        searchInputClassName={styles.searchInput}
        searchButtonClassName={styles.searchButton}
        onSuccess={onSuccessCallback}
        callHandleSubmitOnEnter
        resultsWrapperClassName={styles.searchResults}
        formRef={formRef}
        id="home-page-hero-search"
        onSelect={(selection, isPropertyPath) => {
          // Only go to onboarding if search is for a place, not a property
          if (onboardingIsActive && !isPropertyPath) {
            openOnboardingModal(selection)
          } else {
            assignOrOpenPath({
              path: selection.value,
              isPropertyPath,
              isMobile,
            })
          }
        }}
        scrollToTopOnFocus={isMobile}
        recentSearch={recentSearch}
      />
    </form>
  )
}
