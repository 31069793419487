import { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import {
  STATIC_MAP_DEFAULT_TYPE,
  STATIC_MAP_DEAFULT_MAP_FORMAT,
} from '../map/map.const'

import styles from './static-map.module.css'

import {
  getImageSize,
  getStaticMapCenter,
  getEncodedPolygonList,
  generateMapShape,
  generateStaticMapUrl,
  generateStaticStreetUrl,
} from './static-map-utils'
import type { Feature, Polygon, MultiPolygon } from 'geojson'

// For use in place of small images (listing card and smaller)
import noImageSmall from '@brand/static/images/no-image-small.png'
// For use in place of large images (e.g., detail page gallery photo)
import noImageLarge from '@brand/static/images/no-image-large.png'
import signUrl from './static-map-sign-url'

export type Location = {
  city?: string | null
  state?: string | null
  shapeFile?: Record<string, unknown> | null
  name?: string | null
  lat?: string | number | null
  lng?: string | number | null
}

export type StaticMapProps = {
  city?: string | null
  state?: string | null
  shapeFile?: Record<string, unknown> | null
  name?: string | null
  lat?: string | number | null
  lng?: string | number | null
  width?: number
  height?: number
  sourceWidth?: number
  sourceHeight?: number
  markerUrl?: string | null
  zoom?: number
  scale?: number
  mapType?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain'
  showMarker?: boolean
  format?: 'png' | 'jpg' | 'webp'
}

export type StaticStreetViewProps = {
  name?: string | null
  lat?: number | null
  lng?: number | null
  width?: number
  height?: number
  fov?: number | string
  heading?: number | string
  radius?: number | string
  source?: string
  pitch?: number | string
  fallbackImageSize: 'sm' | 'lg'
  className?: string
}

export const MAP_DIMENSION = { width: 1200, height: 250 }
export const GALLERY_IMAGE = { width: 471, height: 314 }

export function StaticStreetView({
  className,
  lat, // Required for show picture(latitude)
  lng, // Required for show picture(longitude)
  width, // Width of the picture
  height, // Height of the picture
  name, // Name | Address of the property for alt text
  pitch, // default is 0, specify up or down angle of camera 90 straight up -90 down
  radius, // default is 50, wide angle of picture
  source, // source of picture, could be outdoor or default
  heading, // indicates by which angle camera position range from 0 to 360, default is front of closest building
  fov, // default is 90, it is zoom range from 0 to 120
  fallbackImageSize, // size of fallback image small or large
}: StaticStreetViewProps) {
  const fallbackImage =
    fallbackImageSize === 'sm' ? noImageSmall.src : noImageLarge.src
  const [signedImageURL, setSignedImageUrl] = useState<string | null>(null)
  const imgSrc = generateStaticStreetUrl({
    location: {
      lat,
      lng,
    },
    size: {
      width: width || GALLERY_IMAGE.width,
      height: height || GALLERY_IMAGE.height,
    },
    query: {
      pitch,
      radius,
      source,
      heading,
      fov,
    },
  })
  useEffect(() => {
    if (!(lat && lng)) {
      return setSignedImageUrl(fallbackImage)
    }
    if (typeof signedImageURL !== 'string') {
      signUrl(imgSrc, (data) => setSignedImageUrl(data || fallbackImage))
    }
  }, [fallbackImage, imgSrc, lat, lng, signedImageURL])

  const altText = name || 'Static Street View Photo'

  return signedImageURL ? (
    <Image
      width={width || GALLERY_IMAGE.width}
      height={height || GALLERY_IMAGE.height}
      src={signedImageURL}
      alt={signedImageURL !== fallbackImage ? altText : 'Image Not Available'}
      className={className || styles.staticMap}
    />
  ) : null
}

export function StaticMap({
  city,
  state,
  shapeFile,
  name,
  lat,
  lng,
  zoom,
  markerUrl,
  scale,
  showMarker,
  width = MAP_DIMENSION.width,
  height = MAP_DIMENSION.height,
  sourceWidth,
  sourceHeight,
  format = STATIC_MAP_DEAFULT_MAP_FORMAT,
  mapType = STATIC_MAP_DEFAULT_TYPE,
}: StaticMapProps) {
  const center = getStaticMapCenter({
    city,
    state,
    lat,
    lng,
  })

  const fallbackCenterMap = useMemo(
    () =>
      generateMapShape({
        center,
        mapShape: null,
        zoom,
      }),
    [center, zoom]
  )

  const centerOrShape = useMemo(
    () =>
      generateMapShape(
        shapeFile
          ? {
              showMarker,
              mapShape: getEncodedPolygonList(
                shapeFile as unknown as Feature<MultiPolygon | Polygon>
              ),
              center,
              zoom,
            }
          : {
              showMarker,
              center,
              markerUrl,
              zoom,
            }
      ),
    [showMarker, shapeFile, center, markerUrl, zoom]
  )

  const src = generateStaticMapUrl(centerOrShape, {
    size: getImageSize({
      width: sourceWidth || width,
      height: sourceHeight || height,
    }),
    mapType,
    ...(scale && { scale }),
    format,
  })

  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    if (width) {
      setImgSrc(src)
    }
  }, [width, height, src])

  return (
    <Image
      width={width || MAP_DIMENSION.width}
      height={height || MAP_DIMENSION.height}
      src={imgSrc}
      priority
      unoptimized
      style={{ objectFit: 'cover' }}
      alt={name || `${city}, ${state}`}
      onError={() => {
        setImgSrc(fallbackCenterMap)
      }}
      className={styles.staticMap}
    />
  )
}
