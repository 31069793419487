export default function signUrl(
  urlToSign: string,
  signCallback: (url: string) => void
) {
  void fetch('/api/sign-url', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ url: urlToSign }),
  })
    .then((res) => res.json())
    .then((data) => signCallback(data?.signedImageUrl || urlToSign))
}
