/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type ListingWithTotalCostQueryVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  filters: Types.UtilityEstimateListingFilters;
}>;


export type ListingWithTotalCostQuery = { listing?: { utilityEstimates?: { electricity?: { usage?: string | null, priceRange?: { max?: number | null, min?: number | null } | null } | null } | null } | null };

export type Fees_ListingFragment = { fees?: { adminFee?: string | null, applicationFee?: string | null, salesTax?: number | null, insurance?: { offeredByProperty?: string | null, requirementInfo?: string | null, minLiability?: number | null, minProperty?: number | null, notes?: string | null } | null, amenity?: { fee?: number | null, feeType?: string | null, notes?: string | null } | null, utilities?: { included?: { utilities?: Array<string> | null, notes?: string | null } | null, tenantPaid: Array<{ bedCount?: number | null, utilities?: Array<string> | null, monthlyTotal?: string | null, isEstimate?: boolean | null, notes?: string | null }> } | null } | null };

export type TotalCostAndFees_ListingFragment = { address?: string | null, id: string, location?: { city?: string | null, stateAbbr?: string | null, zip?: string | null } | null, bedRange?: { min?: number | null, max?: number | null } | null, priceRange?: { min?: number | null, max?: number | null } | null, parking: Array<{ assignedFee?: string | null, perSpaceFee?: string | null, type?: string | null }>, petPolicies: Array<{ id?: Types.PetPolicyIds | null, additionalRent?: number | null, deposit?: number | null, initialFee?: string | null, label?: string | null }>, propertyManagementCompany?: { id?: string | null } | null, floorPlans?: Array<{ bedCount?: number | null, deposit?: number | null, availabilityStatusCode?: Types.AvailabilityStatus | null, priceRange?: { min?: number | null, max?: number | null } | null }> | null, fees?: { adminFee?: string | null, applicationFee?: string | null, salesTax?: number | null, insurance?: { offeredByProperty?: string | null, requirementInfo?: string | null, minLiability?: number | null, minProperty?: number | null, notes?: string | null } | null, amenity?: { fee?: number | null, feeType?: string | null, notes?: string | null } | null, utilities?: { included?: { utilities?: Array<string> | null, notes?: string | null } | null, tenantPaid: Array<{ bedCount?: number | null, utilities?: Array<string> | null, monthlyTotal?: string | null, isEstimate?: boolean | null, notes?: string | null }> } | null } | null };

export type UtilityEstimates_ListingFragment = { utilityEstimates?: { electricity?: { usage?: string | null, priceRange?: { max?: number | null, min?: number | null } | null } | null } | null };

export const Fees_ListingFragmentDoc = `
    fragment Fees_ListingFragment on Listing {
  fees {
    adminFee
    applicationFee
    insurance {
      offeredByProperty
      requirementInfo
      minLiability
      minProperty
      notes
    }
    amenity {
      fee
      feeType
      notes
    }
    salesTax
    utilities {
      included {
        utilities
        notes
      }
      tenantPaid {
        bedCount
        utilities
        monthlyTotal
        isEstimate
        notes
      }
    }
  }
}
    `;
export const TotalCostAndFees_ListingFragmentDoc = `
    fragment TotalCostAndFees_ListingFragment on Listing {
  ...Fees_ListingFragment
  location {
    city
    stateAbbr
    zip
  }
  address
  id
  bedRange {
    min
    max
  }
  priceRange {
    min
    max
  }
  parking {
    assignedFee
    perSpaceFee
    type
  }
  petPolicies {
    id
    additionalRent
    deposit
    initialFee
    label
  }
  propertyManagementCompany {
    id
  }
  floorPlans {
    bedCount
    priceRange {
      min
      max
    }
    deposit
    availabilityStatusCode
  }
}
    `;
export const UtilityEstimates_ListingFragmentDoc = `
    fragment UtilityEstimates_ListingFragment on Listing {
  utilityEstimates(filters: $filters) {
    electricity {
      usage
      priceRange {
        max
        min
      }
    }
  }
}
    `;
export const ListingWithTotalCostDocument = `
    query ListingWithTotalCost($listingId: ID!, $filters: UtilityEstimateListingFilters!) {
  listing(id: $listingId) {
    ...UtilityEstimates_ListingFragment
  }
}
    ${UtilityEstimates_ListingFragmentDoc}`;

    export function createListingWithTotalCostFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchListingWithTotalCost(variables: ListingWithTotalCostQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<ListingWithTotalCostQuery, ListingWithTotalCostQueryVariables>(ListingWithTotalCostDocument, variables, headers);
      }
    }

