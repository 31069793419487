'use client'

import { useState } from 'react'
import Image from 'next/image'
import { yieldOrContinue } from 'main-thread-scheduling'
import { setIsomorphicCookie } from '@rentpath/cookies'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { Anchor } from '../../../components/anchor/anchor'
import { IconButton } from '../../../components/icon-button/icon-button'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'

import styles from './relief-banner.module.css'
import noticeIcon from '../assets/notice-icon.png'
import { RELIEF_BANNER_COOKIE_KEY } from './relief-banner.const'

const ONE_DAY = 86_400

export function ReliefBanner({
  shouldShowBanner,
}: {
  shouldShowBanner: boolean
}) {
  const [bannerOpen, setBannerOpen] = useState(shouldShowBanner)
  const reliefEnabled = useFeatureVariable(
    ['la_disaster_relief', 'enabled'],
    false
  )

  async function closeBanner() {
    setBannerOpen(false)

    await yieldOrContinue('idle')
    setIsomorphicCookie(RELIEF_BANNER_COOKIE_KEY, 'true', undefined, {
      maxAge: ONE_DAY,
    })
  }

  if (!bannerOpen || !reliefEnabled) {
    return null
  }

  return (
    <div className={styles.banner}>
      <Image
        src={noticeIcon}
        alt="Notice Icon"
        width={100}
        height={100}
        className={styles.bannerIcon}
      />
      <span className={styles.bannerText}>
        Find help and housing after LA fires –{' '}
        <Anchor
          href="/support/2025-los-angeles-fires"
          className={styles.bannerLink}
        >
          Explore Resources
        </Anchor>
      </span>
      <IconButton
        onClick={closeBanner}
        icon={<CloseIcon />}
        label="Close Banner"
      />
    </div>
  )
}
