/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type ContactProperty_ListingFragment = { offMarket: boolean, hasLeadEmails: boolean, mitsPhone: { raw?: string | null, formatted?: string | null } };

export const ContactProperty_ListingFragmentDoc = `
    fragment ContactProperty_ListingFragment on Listing {
  offMarket
  hasLeadEmails
  mitsPhone {
    raw
    formatted
  }
}
    `;



