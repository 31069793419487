import { LeadTour, TourType } from '../../../__generated__/api-types'

const tourToLeadTypeMap = {
  [TourType.InPerson]: LeadTour.AgentGuided,
  [TourType.SelfGuided]: LeadTour.SelfGuided,
  [TourType.VideoCall]: LeadTour.VideoCall,
}

export function getLeadTourType(tourType: TourType): LeadTour {
  return tourToLeadTypeMap[tourType] || LeadTour.Unspecified
}
